<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4 class="card-header-title">{{$t('components.salesInstallmentsPayments.salesInstallmentsPaymentsPayments')}}</h4>
        <router-link
          :to="'/salesInstallmentsPayments/create?sales_installment_id=' + $route.params.id"
          class="btn btn-sm btn-primary"
          v-if="
            $parent.item.stat == 1 &&
            $site.salesPayments_allow &&
            ($user.admin ||
              $user.role.sales_payments_add)
          "
        >
          <i class="fas fa-plus"></i> {{$t('components.salesInstallmentsPayments.addition')}}
        </router-link>
      </div>
      <indexTable
        :searchText="$t('components.salesInstallmentsPayments.FindAInstallment')"
        :withoutCard="true"
        :filter="{sales_installment_id: $route.params.id}"
        :emptyTableText="$t('components.salesInstallmentsPayments.thereAreNosalesInstallments')"
        :emptyTableSubText="$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain')"
        :cloumns="[
          { column: 'code', title: $t('components.salesInstallmentsPayments.Payment'), type: 'mainLink', sort: true },
          {
            column: 'customer_id',
            title: $t('components.salesInstallmentsPayments.customerName'),
            type: 'link',
            to: 'customer',
            sort: true,
            link: true,
          },
          {column: 'safe_id',    title:$t('salesInstallmentsPayments.Treasury'),      type: 'link', to:'safe', sort: true, link: true},
          {
            column: 'date',
            title: $parent.$t('components.salesInstallmentsPayments.paymentDate'),
            type: 'text',
            sort: true,
          },
          {column: 'sales_installment_id',    title: $t('salesInstallmentsPayments.salesInstallment') ,type: 'linksalesInstallment', to:'salesInstallment', sort: true, link: true},
          {
            column: 'description',
            title: $parent.$t('components.salesInstallmentsPayments.thatsAbout'),
            type: 'text',
            sort: true,
          },
          {
            column: 'cost',
            title: $parent.$t('components.salesInstallmentsPayments.amount'),
            type: 'text',
            sort: true,
          },
          {
            column: 'options',
            title: $parent.$t('components.salesInstallmentsPayments.settings'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role:
                  $user.admin ||
                  $user.role.sales_cheques_edit,
              },
              {
                name: 'delete',
                role:
                  $user.admin ||
                  $user.role.sales_cheques_delete,
              },
            ],
          },
        ]"
        :deleteText="{
          attention: $parent.$t('allerts.Attention'),
          areYouReallySureToDeleteTheItem:
            $parent.$t('allerts.areYouReallySureToDeleteTheItem'),
          close: $parent.$t('allerts.close'),
          confirm: $parent.$t('allerts.confirm'),
          loading: $parent.$t('allerts.loading'),
        }"
      />
    </div>
  </div>
</template>
<script>
import indexTable from "../../elements/index/indexTable.vue";
export default {
  data() {
    return {
      path: "/salesInstallmentsPayments"
    };
  },
  components: {
    indexTable,
  },
};
</script>
